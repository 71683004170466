import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GrSearch } from "react-icons/gr";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const HeatmapAccordian = props => {
    const [searchText, setSearchText] = useState('');

    const onSelectAccordianChoice = (id, name) => {
        if (props.accordianSelectedData.includes(id) || props.accordianSelectedName.includes(name)) {
            props.setAccordianSelectedData(props.accordianSelectedData.filter(function (item) {
                return item !== id
            }))
            props.setAccordianSelectedName(props.accordianSelectedName.filter(function (item) {
                return item !== name
            }))
        } else {
            props.setAccordianSelectedData([...props.accordianSelectedData, id])
            props.setAccordianSelectedName([...props.accordianSelectedName, name])
        }
    }

    const handleSelectAll = (data, setData) => {
        var geofenceArr = Object.values(data), temp = [], dataNames = [];
        geofenceArr.forEach((item, i) => {
            temp.push(item[props.setIdsParam])
            dataNames.push([item[props.name]])
        })
        setData(temp)
        props.setAccordianSelectedName(dataNames)
    }

    const handleDeSelectAll = () => {
        props.setAccordianSelectedData([])
        props.setAccordianSelectedName([])
    }

    return (
        <div className="form-field-shadow-filter-box">
            <div className="accordian-heading mb-1">
                <div className='accordian-title'> {props.title} </div>
                {props.accordianState ?
                    <div className='accordian-functions' style={{ display: "inline-block", width: "72%" }}>
                        <div onClick={() => {
                            props.accordianSelectedData.length == props.accordianData.length ? handleDeSelectAll() : handleSelectAll(props.accordianData, props.setAccordianSelectedData)
                        }} className="col-3 row justify-content-center form-field-geofence-selct-all-button">
                            {props.accordianSelectedData.length == props.accordianData.length ? "Deselect All" : "Select All"}
                        </div>
                        <form className=" col-7 search-input-box" onSubmit={() => { alert("search") }}>
                            <GrSearch className="mx-1" />
                            <input className="search-input" type="text" value={searchText} placeholder='Search..' onChange={(val) => { setSearchText(val.target.value) }} />
                        </form>
                    </div> : null}
                {props.isAccordian ?
                    <FontAwesomeIcon onClick={() => {
                        props.setAccordianState(!props.accordianState)
                    }} icon={props.accordianState ? faChevronUp : faChevronDown} className="col-1 accordian-arrow" size="lg" /> : null}
            </div>
            {props.accordianState ?
                <div className='row accordian-data-space justify-content-center'>
                    {
                        props.accordianData?.filter(post => {
                            if (searchText === '') {
                                return post;
                            } else if (post.name.toLowerCase().includes(searchText.toLowerCase())) {
                                return post;
                            }
                        })?.map((val, key) => {
                            return (
                                <div onClick={() => { onSelectAccordianChoice(val[props.setIdsParam], val[props.name]) }} className='col-5 form-field-radio-input' key={key}>
                                    <div className='row justify-content-between align-items-center'>
                                        <div
                                            className={props.accordianSelectedData.indexOf(val[props.setIdsParam]) >= 0 ? 'col-2 rectangle-blue' : 'col-2 rectangle-gray'}>
                                        </div>
                                        <div style={{ textAlign: "left" }} className='col-9'>{val[props.name]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )

}

export default HeatmapAccordian