import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ReportDashboard from '../../components/Reports/reportDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { UserTable } from '../../components';
import { Forms } from '../../components';
import { Button } from 'react-bootstrap';
import Logo from "../../assets/images/logo.png"
import { toast } from 'react-toastify';
import { ApiHandler } from '../../hooks/apiHandler';
import { API_METHODS, ENDPOINTS } from '../../util/constants/constants';
import { ThreeBounce } from 'better-react-spinkit';
import { Modal } from 'react-bootstrap';
import { validateEmail, matchPassword, validateName } from "../../util/constants/helper";

const Users = props => {

  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cnfPassword, setCnfPassword] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [refresh, setRefresh] = useState(true);


  //error and loader
  // const [error, setError] = useState(null);
  // const [loading, setloading] = useState(false);


  const registerUser = async () => {
    if (validate()) {
      try {
        const reqParam = registerationData();;
        const method = API_METHODS.POST;
        const endPoint = ENDPOINTS.registerUser;
        // setloading(true);
        const response = await ApiHandler({ reqParam, method, endPoint });
        // setloading(false);
        if (response.status !== 200) {
          response.data?.message ? toast.error(response.data.message) : toast.error("You have entered an invalid username or password");
          // setError(true);
        }
        else {
          // setToken(response.data?.token)
          toast.success(response.data?.message);
          setShow(false);
          setRefresh(!refresh);
          setFirstName('');
          setLastName('');
          setEmail('');
        }
      } catch (e) {
        console.log(e);
        toast.error(e);
      }
    } else {
      toast.error("Invalid username or password provided");
    }
  };


  const validate = () => {
    return validateEmail(email) && validateName(firstName) && validateName(lastName) && matchPassword(password, cnfPassword);
  }

  const registerationData = () => {
    return {
      firstname: firstName,
      lastname: lastName,
      email: email,
      // password : password,
      // confirmPassword : cnfPassword,
      role : isAdmin ? "admin" : "user"
      // role: "user",
    }
  }

  return (
    <div className='col-10'>
      <div className='row justify-content-between '>
        <div className=' col-4 report-page-title'>Users</div>
        <div onClick={() => { setShow(true) }} className='col-3 report-create-new-button'>
          Invite User
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{
            textAlign: "left", font: "normal normal medium 20px/25px Helvetica Neue",
            letterSpacing: "0px",
            color: "#000000",
          }}>
            <div class="row p-0 m-0">

              <div class='d-flex account-form-input col-md-5 col-sm-10 '><label className='account-form-label' for='name'>First Name</label>
                <input className=' account-form-input-box' value={firstName} type='text' name='name' onChange={(e) => {
                  setFirstName(e.target.value)
                }}></input>
              </div>

              <div class=' d-flex account-form-input  col-md-5 col-sm-10 '><label className='account-form-label' for='name'>Last Name</label>
                <input className=' account-form-input-box' value={lastName} type='text' name='name' onChange={(e) => {
                  setLastName(e.target.value)
                }}></input>
              </div>

              <div class='d-flex  account-form-input  col-md-5 col-sm-10 '><label className='account-form-label' for='name'>Email</label>
                <input className=' account-form-input-box' value={email} type='text' name='name' onChange={(e) => {
                  setEmail(e.target.value)
                }}></input>
              </div>

              {/* <div class='  account-form-input  col-md-5 col-sm-10 '><label className='account-form-label' for='name'>Password</label>
                <input className=' account-form-input-box' value={password} type='text' name='name' onChange={(e) => {
                  setPassword(e.target.value)
                }}></input>
              </div>

              <div class='d-flex  account-form-input  col-md-5 col-sm-10 '><label className='account-form-label' for='name'>Confirm password</label>
                <input className=' account-form-input-box' value={cnfPassword} type='text' name='name' onChange={(e) => {
                  setCnfPassword(e.target.value)
                }}></input>
              </div> */}

              <div class='d-flex pt-2 col-md-5 col-sm-10 '>
                <div className="container">
                  <div className="toggle-switch">
                    <input type="checkbox" className="checkbox"
                      name="role" id="role" checked={isAdmin} onChange={() => {setIsAdmin(!isAdmin)}}/>
                    <label className="label" htmlFor="role">
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { registerUser() }}>Invite</Button>
        </Modal.Footer>
      </Modal>


      <UserTable refresh={refresh} />

    </div>
  )
}


export default Users;