import {  Routes, Route, Navigate} from "react-router-dom";
import "./assets/styles/app.scss";

// routes
import {userRoutes , authRoutes,  adminRoutes, printRoutes } from "./routes";
import { Header } from "./components";
// import { UserProvider } from "./hooks/auth";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./hooks/auth";
import { useContext, useEffect } from "react";
import { Login } from "./pages";
const App = () => {
  const {isLoggedIn , logout} = useContext(AuthContext);

  useEffect(()=>{if(!isLoggedIn) { logout()}},[isLoggedIn])
  const isAdmin = localStorage.getItem("ACCESS");
  return (
  <div className="d-flex">
    {isLoggedIn  && <Header/>}
     <ToastContainer closeButton={false} position="bottom-right" />
     <Routes>

         <Route path="/print" exact>
            { printRoutes.map(({ path, exact , component }, key) => (
                <Route exact={exact} path={path} element={ component } key={key} />
            ))}
          </Route>

          <Route path="/" exact>
            { userRoutes.map(({ path, exact , component }, key) => (
                <Route exact={exact} path={path} element={isLoggedIn ? component : <Login /> } key={key} />
            ))}
          </Route>
          
          

          { isAdmin && adminRoutes.map(({ path, exact , component }, key) => (
            <Route exact={exact} path={path} element={component} key={key} />
          ))}
         
         {!isLoggedIn && authRoutes.map(({ path, exact , component }, key) => (
            <Route path={path} exact element={component} key={key} />
          ))}
          <Route path='*' element={ <Navigate replace to={  "/" } />} />

      </Routes>
      </div>
  );
}

export default App;
