import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {AuthContextProvider} from "./hooks/auth";
import {ReportProvider} from './hooks/reportContext'

ReactDOM.render(
  <AuthContextProvider>
   <ReportProvider> 
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </ReportProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);

