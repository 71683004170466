import React, { useState } from 'react'
import { Forms } from '../../components';
import { Button } from 'react-bootstrap';
import Logo from "../../assets/images/logo.png"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiHandler } from '../../hooks/apiHandler';
import { API_METHODS, ENDPOINTS } from '../../util/constants/constants';
import { ThreeBounce } from 'better-react-spinkit';
import { Modal } from 'react-bootstrap';

const Login = props => {

  const [loading, setloading] = useState(false);
  const [tnc, setTnc] = useState(false)
  const [show, setShow] = useState(false);
  const [email, setEmail ] = useState("");

  const onForgotPassword = async () => {
    try {
      const reqParam = { email : email };
      const method = API_METHODS.POST;
      const endPoint = ENDPOINTS.forgotPassword;
      setloading(true);
      const response = await ApiHandler({ reqParam, method, endPoint });
      setloading(false);
      if (response.status !== 200) {
        response.data?.message ? toast.error(response.data.message) : toast.error("Please try again !");
      }
      else {
        toast.success(response.data?.message);
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  }




  return (
    <div className='login-page w-100'>

      <div className='login-page__form '>
        <img src={Logo} className="my-5 mx-auto login-page__logo" />
        <Forms.LoginForm setFrgtPasswordEmail={setEmail} tnc = {tnc} setTnc = {setTnc} />
      </div>
      <div className='fixed-bottom mx-5 mb-5 col-xl-2 col-lg-4 col-md-4  col-12'>
        {/* <Button className='d-block my-3  login-page__button'>Forgot Username</Button> */}
        <Button  className="my-4 ps-5 pe-5  login-page__button float-start" onClick={() => onForgotPassword()}>{loading ? <ThreeBounce size={10} color='black' /> : 'Forgot Password'}</Button>
        
      </div>
    </div>
  )
}


export default Login;