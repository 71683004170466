import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewReportForm } from '../../components/Forms';
const NewReport = props => {

  return (
    <div>
     <NewReportForm/>

    </div>
  )
}


export default NewReport;