import React, { useEffect, useState,useContext } from 'react' 
import { ApiHandler } from '../../hooks/apiHandler';
import { ENDPOINTS, API_METHODS } from '../../util/constants/constants';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChevronLeft, faChevronRight, faChevronUp,faUserPlus } from '@fortawesome/free-solid-svg-icons';
 
import { Link } from 'react-router-dom';
import { ReportContext } from '../../hooks/reportContext';
import { useLocation } from "react-router-dom";
import edit from '../../assets/images/edit.png'

const NewReportForm = props => {
  const location = useLocation();
  const {scheduleReport,getReport} =useContext(ReportContext); 
  const [toTime, setToTime] = useState(new Date().toISOString().slice(0, 10));
  const [geofenceData, setGeofenceData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [isDay, setIsDay] = useState(false);
  const [isWeek, setIsWeek] = useState(false);
  const [isMonth, setIsMonth] = useState(false);
  const [isYear, setIsYear] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [count, setCount] = useState(3);
  const [recipients, setRecipients] = useState([]);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [reportName, setReportName] = useState([]);
  //geofence Data state
  const [geofenceSelectedData, setGeofenceSelectedData] = useState([]);


  const [vehicleSelectedData, setVehicleSelectedData] = useState([]);

  //accoridan states
  const [geofenceAccordian, setGeofenceAccordian] = useState(true);
  const [vehicleAccordian, setVehicleAccordian] = useState(true);

  const [reportData,setReportData]=useState(null)
  const [weekDay,setWeekDay]=useState(0);
  const [monthDate,setMonthDate]=useState(1);
  const [reportId,setReportId]=useState('');
  const weekdays=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  //getGeofenceData for select options
  useEffect(() => {
    getGeofenceData();
    getDeviceData();
  }, []);


 useEffect(()=>{
  const {search}=location;
   if(search){ 
     console.log(location)

    const reportId=search.substring(1,search.length);
    setReportId(reportId)
     const payload={
       reportId:reportId
     }

     getReport(payload).then((response)=>{
       console.log(response.data)
       setReportData(response?.data)
       updateReportData(response?.data)
     })
     .catch(error=>{
       console.log(error)
     })
     
      
   }
 },[location?.search]);


 const updateReportData=(data)=>{
   setReportName(data?.reportName);
   setVehicleSelectedData(data?.device);
   setGeofenceSelectedData(data?.geofence);
   setRecipients(data?.Recipients)
   setCount(data?.Recipients?.length)
   if(data?.weekDay){
    setWeekDay(data?.weekDay)
  }
  if(data?.monthDate){
    setMonthDate(data?.monthDate)
  }
   const {frequency}=data;
   setIsDay(false)
   setIsWeek(false)
   setIsMonth(false)
   setIsYear(false)
   if(frequency==1){
     setIsDay(true)
   }else if(frequency==2){
     setIsWeek(true)
   }else if(frequency==3){
     setIsMonth(true)
   }else{
     setIsYear(true);
   }
   //setTime
//    let unix_timestamp =  data?.schduledTimestamp
// var date = new Date(unix_timestamp * 1000);
//  setWeekDay(date.getDay())
//  setMonthDate(date.getDate());
// var hours = date.getHours();
 
// var minutes = "0" + date.getMinutes();
 
// var seconds = "0" + date.getSeconds();

 
// var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

// console.log(formattedTime);
// setScheduleTime(formattedTime)
setScheduleTime( data?.schduledTimestamp)
 }

  const getGeofenceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getGeofenceData;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setGeofenceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDeviceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllDevice;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setDeviceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };


  const getFrequency=()=>{
    if(isDay)
      return 1;
    else if(isWeek)
      return 2;
    else if(isMonth)
      return 3;
    return 4;    

  }

 

  const recipientForm = (i) => {
    return (
       <div className='recipients' style={{backgroundColor:i%2?'#fff':'#F6F9FE'}}>
         <div className='name'>
          {recipients[i]?.name}
         </div>
         <div className='name'>
          {recipients[i]?.email}
         </div>

       </div>
      )
  }

  


  return (
    <div className="container">
      <div className="top-bar">
        <div className="report-header">
          <Link style={{ color: "black" }} to="/report">
            <FontAwesomeIcon icon={faChevronLeft} className="me-4" size="lg" />
          </Link>
          <div className="report-header-text">{reportName}</div>
        </div>
        <div className="edit-div" role="button">
          <div className="edit">
            <Link
              className="text-decoration-none link-dark"
              to={{
                pathname: "/new-report",
                search: reportId,
              }}
            >
              {" "}
              <img
                className="me-2"
                src={edit}
                style={{ height: 15, width: 15 }}
              />{" "}
              Edit
            </Link>
          </div>
        </div>
      </div>

      <div className="main">
        <div className="report-left-part">
          <div className="box">
            <div className="bold-text">Frequency</div>
            <div className="scroll-div">
              {isDay
                ? "Daily"
                : isWeek
                ? "Weekly"
                : isMonth
                ? "Monthly"
                : "Yearly"}
            </div>
          </div>
          {/* <div className='box'>
             <div className='bold-text'>Data Time Frame</div>
             <div className='scroll-div'>Daily</div>
          </div> */}
          <div className="box">
            <div className="bold-text">Geofence</div>
            <div className="scroll-div">
              {geofenceData?.map((geofence, i) => {
                return (
                  <>
                    {(geofenceSelectedData.indexOf(geofence?._id) >= 0 || geofenceSelectedData.indexOf(geofence?.code) >= 0) ? (
                      <div key={i} style={{paddingVertical:15}}>{geofence?.name}</div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>
          <div className="box">
            <div className="bold-text">Vehicles</div>
            <div className="scroll-div">
            {deviceData?.map((device, i) => {
                return (
                  <>
                    {vehicleSelectedData.indexOf(device?.device_id) >= 0 ? (
                      <div key={i} style={{paddingVertical:15}}>{device?.name}</div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="report-right-part">
          <div className="box box2">
            <div className="bold-text mb-2">Send Report</div>
            <div className="schedule align-self-start">  
               <div style={{marginRight:20}}><b>Time</b> {scheduleTime}</div>
               <div>
               {
                isDay?' Mon Tue Wed Thu Fri Sat Sun':(isWeek?'Every '+weekdays[weekDay]:(isMonth?'On '+monthDate+'th of every month':'Yearly'))
               }
               </div>
            </div>
          </div>
          <div className="box box2">
            <div className="bold-text align-self-start my-2">Recipients</div>
            <div className="scroll-div">
              {Array(count)
                .fill(0)
                .map((val, i) => {
                  return <>{recipientForm(i)}</>;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewReportForm