import React, { useState, useCallback, useEffect } from 'react'
import help from '../../assets/images/help.pdf';
import pdf from "../../assets/images/pd.png"

const Help = () => {

  const [isTnc, setIsTnc] = useState(true);

  return (
  <>
<div className='col-10'>
      <div className='row justify-content-between '>
        <div className=' col-4 report-page-title'>Help</div>
      </div>
      <div>
        <div className="">
          {/* <div className={isDetails ? " col-1 account-nav-button-blue" : "account-nav-button-gray"} onClick={() => {
            setIsTnc(false)
            setIsDetails(true)
          }} >Help</div> */}
{/* 
          <div className={isTnc ? "account-nav-button-blue" : "account-nav-button-gray"} onClick={() => {
            setIsTnc(true)
            setIsDetails(false)
          }} >User manual</div> */}

        </div>
      </div>
      <div className='row justify-content-center'>
      {
        isTnc ?
          <div className='row col-11 account-tnc-outer-shadow-filter-box-1 '>
            <div className='col-10 account-tnc-shadow-filter-box'>
              <embed
                id="pdf_tnc_area"
                src={help + "#toolbar=0&navpanes=0&scrollbar=0"} type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                height="100%"
                width="100%" />
            </div>
            <a className='col-2 account-button__report   ' href={help} download><img src={pdf} style={{height : "4.4vw"}} /></a>
          </div>
          : null
      }
      </div>
    </div>
  </>
  )
}
export default Help;