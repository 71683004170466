import React from 'react'

const SelectInput = props => {
  return (
   <>
    <select value={props.value} name={props.name} className={props.className} onChange={props.onChange}>
        {
            props.options?.map((val , key) =>{
            return( 
                    <option value={val._id} key={key}>
                         {val.name}
                    </option>
                  )
            })
        }
    </select>
   </>
  )
}


export default SelectInput