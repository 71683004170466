export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete"
};

export const ENDPOINTS = {
  getGeofenceData: "api/device/getgeofence",
  getAllDevice: "api/device/get-all-device",
  getHeatmapData: `api/device/devices`,
  login: "auth/login",
  registerUser: "auth/register",
  forgotPassword: "auth/forgot-password",
  getUserData: "api/user",
  updateUserData: "auth/update-user",
  getAllUserData: "api/user/get-all-user",
  toggleReportStatus: "api/device/report-status",
  toggleUserStatus: "api/user/change-status",
  getAllReports: "api/device/get-all-reports",
  scheduleReport: "api/device/schedule-report",
  getReport: "api/device/getReport",
  deleteReport: (reportId) => `api/device/delete-report/${reportId}`
}

export const SELECTINPUT = {
  month: [
    { _id: 1, name: "1st" },
    { _id: 2, name: "2nd" },
    { _id: 3, name: "3rd" },
    { _id: 4, name: "4th" },
    { _id: 5, name: "5th" },
    { _id: 6, name: "6th" },
    { _id: 7, name: "7th" },
    { _id: 8, name: "8th" },
    { _id: 9, name: "9th" },
    { _id: 10, name: "10th" },
    { _id: 11, name: "11th" },
    { _id: 12, name: "12th" },
    { _id: 13, name: "13th" },
    { _id: 14, name: "14th" },
    { _id: 15, name: "15th" },
    { _id: 16, name: "16th" },
    { _id: 17, name: "17th" },
    { _id: 18, name: "18th" },
    { _id: 19, name: "19th" },
    { _id: 20, name: "20th" },
    { _id: 21, name: "21th" },
    { _id: 22, name: "22th" },
    { _id: 23, name: "23th" },
    { _id: 24, name: "24th" },
    { _id: 25, name: "25th" },
    { _id: 26, name: "26th" },
    { _id: 27, name: "27th" },
    { _id: 28, name: "28th" },
    { _id: 29, name: "29th" },
    { _id: 30, name: "30th" },
  ],

  week: [
    { _id: 0, name: "Sunday" },
    { _id: 1, name: "Monday" },
    { _id: 2, name: "Tuesday" },
    { _id: 3, name: "Wednesday" },
    { _id: 4, name: "Thursday" },
    { _id: 5, name: "Friday" },
    { _id: 6, name: "Saturday" },
  ]
}