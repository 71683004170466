import React, { useEffect, useState, useContext } from "react";
import { TextInput } from '../Input'
import { AuthContext } from '../../hooks/auth';
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ThreeBounce } from 'better-react-spinkit'
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/fontawesome-free-solid'
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import tnc from '../../assets/images/tnc.pdf';
import { FiArrowRight} from "react-icons/fi";
// import { Document, Page } from 'react-pdf'

const LoginForm = props => {


  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [tncEnable, setTncEnable] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const navigate = useNavigate();


  const onLogin = async (val) => {
    if (email && password) {
      // if (!props.tnc) {
      //   props.setShow(true)
      //   setError(false);
      // } else {
      try {
        setloading(true);
        const payload = {
          email: email,
          password: password,
          tnc: false
        }
        if (val == "anc") {
          Object.assign(payload, { tnc: true });
        }
        var response = await login(payload);
        setloading(false);
        if (response?.status == 422) {
          setShow(true)
          setError(false);
        }
        else if (response?.status !== 200) {
          toast.error(response?.data?.message);
          setError(true);
        } else {
          if (val == "anc") {
            setShow(false)
          }
          toast.success("Logged In Successfully");
          setError(null);
          navigate("/");
        }
      } catch (e) {
        console.log(e);
        toast.error(e);
      }
      // }
    } else {
      toast.error("Invalid email or password provided");
      setError(true);
    }
  };


  return (
    <>
      <div className='align-middle mx-auto row col-md-5 col-lg-4 col-11 '>
        <div className="row">
          <TextInput
            type="text"
            name="email"
            className="form-field__login d-block mx-auto col-lg-12 col-md-9 col-12 ps-5"
            value={email}
            placeholder="Enter email"
            onChange={(e) => {
              setEmail(e.target.value);
              props.setFrgtPasswordEmail(e.target.value);
            }}
          />
          <TextInput
            type="password"
            name="password"
            className="form-field__login d-block mx-auto col-lg-12 col-md-9 col-12 ps-5"
            value={password.value}
            placeholder="Enter Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {error &&
            <div className="form-error_login">
              Please provide a valid email and password !
            </div>
          }
          <div  className="d-flex align-items-center justify-content-between   col-lg-12 col-md-9 col-12 px-md-4 px-lg-0 px-0">
            <Link style={{fontSize:12}} onClick={() => { setShow(true); setError(false); }} className=' text-black float-start m-md-2' to="/">Terms & Conditions</Link>
            <Button className="form-field-button__login btn btn-outline-light float-end col-lg-4 col-md-6 col-5 " onClick={onLogin}>
              {loading ? <ThreeBounce size={10} color='blue' /> : <><span style={{paddingLeft:10}}>Sign in</span> <FiArrowRight size={20} /></>}

            </Button>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <embed
            src={tnc + "#toolbar=0&navpanes=0&scrollbar=0"} type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="500px"
            width="100%" />

        </Modal.Body>
        <Modal.Footer>
          <div className="row w-50 justify-content-end">
            <div className="row col-4">
            <input className=""  type="checkbox" checked={tncEnable} onChange={() => {setTncEnable(!tncEnable)}} /> 
            <div className="">I Accept</div>
            </div>
          <Button className="col-5" disabled={!tncEnable} onClick={() => { onLogin("anc"); props.setTnc(true) }}>Continue</Button>
          </div>
          {/* <Button  disabled={!bottom} onClick={() => { onLogin("anc"); props.setTnc(true) }}>Accept and Continue</Button>  */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LoginForm
