import React, {  useState, useContext } from "react";
import {TextInput} from '../Input'
import { AuthContext } from '../../hooks/auth';
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ThreeBounce } from 'better-react-spinkit'
import { useNavigate } from "react-router-dom";
import { validateEmail , matchPassword , validateName } from "../../util/constants/helper";
import { ApiHandler } from "../../hooks/apiHandler";
import { API_METHODS,ENDPOINTS } from "../../util/constants/constants";

const RegisterationForm = props => {

    const {  setToken} = useContext(AuthContext);
    
    //form values
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName , setLastName ] = useState("");
    const [email , setEmail ]= useState("");

    //error and loader
    const [error, setError] = useState(null);
    const [loading, setloading] = useState(false);
    
    const navigate = useNavigate();

    const registerUser = async () => {
        if (validate()) {
        try {
            const reqParam =  registerationData();;
            const method = API_METHODS.POST;
            const endPoint = ENDPOINTS.registerUser;
            setloading(true);
            const response = await ApiHandler({ reqParam, method, endPoint });
              setloading(false);
              if (response.status !== 200) {
                response.data?.message ?  toast.error(response.data.message) :toast.error("You have entered an invalid username or password");
                setError(true);
              }
              else {
                setToken(response.data?.token)
                toast.success(response.data?.message);
                setError(null);
                navigate("/");
              }
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
        } else {
        toast.error("Invalid username or password provided");
        setError(true);
        }
    };


    const validate = () =>{
        return validateEmail(email) && validateName(firstName) && validateName(username) && matchPassword(password , confirmPassword);
    }
  
    const registerationData = () =>{
      return {
        firstname : firstName,
        lastname : lastName,
        email: email,
        username : username,
        password : password
      }
    }

    return (
      <>
        <div className='glass_container align-middle mx-auto row col-5 mt-5' id="registerationForm">
         <div className="row">
            <TextInput 
            type="text"
            name="firstname"
            className="form-field__register d-block mx-auto my-1 col-9"
            value={firstName}
            placeholder="Enter First Name"
            required ={ true}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
        />


        <TextInput 
            type="text"
            name="lastname"
            className="form-field__register d-block mx-auto my-1  col-9"
            value={lastName}
            placeholder="Enter Last Name"
            required ={ true}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
        />
        <TextInput 
            type="email"
            name="email"
            className="form-field__register d-block mx-auto my-1   col-9  "
            value={email}
            placeholder="Enter Email"
            required ={ true}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
        /> 

        <TextInput 
            type="text"
            name="username"
            className="form-field__register d-block mx-auto my-1  col-9"
            value={username}
            placeholder="Enter Username"
            required ={ true}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
        />

        <TextInput 
            type="password"
            name="password"
            className="form-field__register d-block mx-auto my-1  col-9"
            value={password}
            placeholder="Enter Password"
            required ={ true}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
        />

        <TextInput 
            type="password"
            name="confirm-password"
            className="form-field__register d-block mx-auto my-1  col-9 "
            value={confirmPassword}
            placeholder="confirm password"
            required ={ true}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
        />  
        
         {error &&
             <div className="form-error_login">
               Please provide valid Details!
            </div>
          }
        <Button className="form-field-button__register btn btn-outline-light mx-auto col-10"  onClick={registerUser}> 
            {loading ? <ThreeBounce size={10} color='black' /> : "Register" }
        </Button>
        </div>
        </div>
      </>
    )
}

export default RegisterationForm;