import React from 'react';

const TextInput = (props) => {
  return (
    <>
        <input 
            type={props.type} 
            name={props.name}
            value={props.value }
            placeholder={props.placeholder} 
            className={props.className} 
            id={props.id}
            onChange={props.onChange} 
            onBlur={props.onBlur}
            max = {props?.maxDate}
            min = {props?.minDate}
         />
    </>
  )
}

export default TextInput;