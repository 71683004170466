import React, { useState, useEffect  } from "react";  
import { toast } from "react-toastify";
import { API_METHODS ,ENDPOINTS } from "../util/constants/constants";
import { ApiHandler } from "./apiHandler";

export const ReportContext = React.createContext({});

export const ReportProvider = (props) => {  
 
 const [reports,setReports]=useState([]);

  const toggleStatus=async(report)=>{
      const data={
          status:!report?.isActive ,
          reportId:report?._id
      }
      
    try {
        const response = await ApiHandler({
          reqParam: data,
          method: API_METHODS.PUT,
          endPoint: ENDPOINTS.toggleReportStatus,
        });
        if (response.status === 200) { 
            getAllReports();
        }
        return response;
      } catch (err) {
        console.log(err); 
      }
  }

  const getAllReports=async()=>{
     
  try {
      const response = await ApiHandler({
         
        method: API_METHODS.GET,
        endPoint: ENDPOINTS.getAllReports,
      });
      if (response.status === 200) { 
        setReports(response?.data)
      }
      return response;
    } catch (err) {
      console.log(err); 
      return err;
    }
}


const scheduleReport=async(payload)=>{
    
    try {
        const response = await ApiHandler({
            reqParam: payload, 
          method: API_METHODS.POST,
          endPoint: ENDPOINTS.scheduleReport,
        });
        if (response.status === 200) {
          toast.success("Report added successfully !")
         
           console.log("=====",response) ;
          //  navigate("/report");
          window.location.replace("/report");
        } else {
          toast.error(response.data.message)
        }
        return response;
      } catch (err) {
        console.log(err); 
        return err;
      }  
}

const updateReport=(reports)=>{
    setReports(reports);
}

const getReport=async(payload)=>{
  try {
    const response = await ApiHandler({
      reqParam:payload, 
      method: API_METHODS.POST,
      endPoint: ENDPOINTS.getReport,
    });
    if (response.status === 200) {  
    }
    return response;
  } catch (err) {
    console.log(err); 
    return err;
  }
}


   

 
  return (
    <ReportContext.Provider
      value={{  
         toggleStatus,
         getAllReports,
         scheduleReport,
         reports,
         updateReport,
         getReport
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};
 
