import React, { useState} from 'react'
import { Heatmap , Forms } from '../../components/';

const Home = () => {
  const [heatmapData,  setHeatmapData] = useState(null);

  return (
  <>
    <Forms.HeatmapForm setData={setHeatmapData} />
  </>
  )
}
export default Home;