import React, { useState, useEffect } from 'react'
import { ApiHandler } from '../../hooks/apiHandler';
import { API_METHODS } from '../../util/constants/constants';
import { ENDPOINTS } from '../../util/constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEdit, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { ThreeBounce } from 'better-react-spinkit'
import { toast } from 'react-toastify';


const UserTable = props => {

    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(false);

    const toggleUserStatus=async(user)=>{
        // setloading(true); 
        const data={
            status:!user?.isActive ,
            userId:user?._id
        }
        
      try {
          const response = await ApiHandler({
            reqParam: data,
            method: API_METHODS.PUT,
            endPoint: ENDPOINTS.toggleUserStatus,
          });
          if (response.status === 200) { 
            getAllUserData();
            console.log(response)
            toast.success(response?.data?.message)
          }
          return response;
        } catch (err) {
          console.log(err); 
        }
        // setloading(false);
    }

    const getAllUserData = async () => {
        setLoading(true)
        const reqParam = {};
        const method = API_METHODS.GET;
        const endPoint = ENDPOINTS.getAllUserData;
        try {
            const response = await ApiHandler({ reqParam, method, endPoint });
            setLoading(false)
            setUsersData(response.data.data.users)

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllUserData();
    }, [props.refresh]);

    return (
        <div className=" users-dashboard-outer-box">
            <div className="row justify-content-between report-dashboard-title-box">
                <div className="col-2 report-dashboard-title"> First Name</div>
                <div className="col-2 report-dashboard-title"> Last Name </div>
                <div className="col-2 report-dashboard-title"> Email </div>
                <div className="col-2 report-dashboard-title"> Role</div>
                <div className="col-2 report-dashboard-title"> Enable/Disable</div>
            </div>
            <div className=" users-dashboard-blue-outer-box">
            {
                loading ? 
                <div className="row justify-content-between users-dashboard-blue-box">
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>

                        </div> : null
            }
            {
                usersData?.map((val, key) => {
                    return (
                        <div className="row justify-content-between users-dashboard-blue-box" style={{backgroundColor:key%2?'#fff':'#F6F9FE'}}>
                            <div className="col-2 report-dashboard-content"> {val.firstname}</div>
                            <div className="col-2 report-dashboard-content">  {val.lastname} </div>
                            <div className="col-2 report-dashboard-content"> {val.email} </div>
                            <div className="col-2 report-dashboard-content"> {val.role}</div>
                            <div className="col-2 report-dashboard-content"> <FontAwesomeIcon type="button" onClick={()=>{toggleUserStatus(val)}}  icon={val?.isActive?faToggleOn:faToggleOff} color="#0096FF"  size="2x" /></div>
                        </div>
                    )
                })
            }
</div>
        </div>
    )

}

export default UserTable