import { Button } from 'bootstrap';
import React, { useState, useEffect } from 'react'
import { HeatmapForm } from '../Forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/fontawesome-free-solid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import pdf from "../../assets/images/pd.png"
import WanderingCubes from 'better-react-spinkit/dist/WanderingCubes';
import csv from "../../assets/images/csv.png"


const Heatmap = ({ data, downloadCSVData, title, classNum, widthClass, borderClass, isVisible, fromDate, toDate }) => {
  const [row, setRow] = useState(0);
  const [col, setCol] = useState(0);
  
  useEffect(() => {
    setRow(data?.length);
    setCol(data?.[0]?.length);
  }, [data])

  const calcOpacity = (col) => {
    var arr = data.map(function (row) { return Math.max.apply(Math, row); });
    var max = Math.max.apply(null, arr);
    if (arr.every(item => item <= 0)) return "FFF";
    if (col / max >= 0.8) {
      return "08519C"
    } else if (col / max > 0.6) {
      return "3082BC"
    } else if (col / max > 0.4) {
      return "83BBDC"
    } else if (col / max > 0.2) {
      return "CADFEC"
    } else if (col / max > 0) {
      return "ECF2FA"
    } else {
      return "FFF"
    }
  }

  var array = data.map(function (row) { return Math.max.apply(Math, row); });
  var maximum = Math.max.apply(null, array);

  const printDocument = async () => {
    document.getElementById('pdfprint').style.display = 'flex';
    document.getElementById('squareloader').style.display = 'flex';
    document.getElementById('heatmap').style.height = 'auto';
    document.getElementById('heatmap').style.overflowY = 'auto';
    let pdf;
    const input = document.getElementById('pdfprint1');
    await html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf = new jsPDF('p', 'px', [3508 , 2480]);
      pdf.addImage(imgData, 'JPEG', 0, 0, 2880, 3508, 'someAlias', 'FAST');      
      pdf.addPage("portrait","portrait")
    });
      const input2 = document.getElementById('pdfprint2');
     await html2canvas(input2)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          let width = canvas.width;
          let height = canvas.height;
          pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        });
    if(fromDate == toDate) {
      pdf.save(`RotowaroEmbankmentReport_${fromDate}.pdf`);
    } else { 
      pdf.save(`RotowaroEmbankmentReport_${fromDate}_${toDate}.pdf`);
    }
    document.getElementById('squareloader').style.display = 'none';
    document.getElementById('pdfprint').style.display = 'none';
    document.getElementById('heatmap').style.height = '88vh';
    document.getElementById('heatmap').style.overflowY = 'scroll';
  }


  return (
    <div className={` ms-${widthClass ? "5 " : "0 "} `} style={{ fontSize: "0.6vw" }}>
      <div className={`row ms-${widthClass ? "5 " : "1 "} `} >
        <div className={'col-10 d-flex ' +  widthClass + classNum} id="heatmap"> 
          <table className={''   +borderClass} rows={row} cols={col}>
            <tbody>
              <tr >
                <td ><h5 id="table-title" style={{ display: 'none' }}>{title}</h5></td>
              </tr>
              <tr className='' style={{ fontSize: "0.55vw" }}>
                <td className='p-2'></td>
                {
                  col && data[0].map((col, i) => {
                    return <td key={i}>{i + 1}</td>
                  })
                }
              </tr>
              {
                row && data.map((val, i, self) => {
                  return (
                    <tr key={i}>
                      <td className='' style={{ backgroundColor: "rgb(36,114,237,0.06)", fontSize: "0.55vw", }} >{data.length - i}</td>
                      {val.map((col, j) => {
                        return (
                          <td key={j} style={{  fontSize: "0.55vw", backgroundColor: "#" + calcOpacity(col) , color: calcOpacity(col) == "08519C" || calcOpacity(col) == "3082BC" ? "white" : "rgba(0, 0, 0, 0.6 )" }} >
                            {col >= 0 ? col : ""}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <table className={ borderClass + ' d-flex flex-column heatamp-button__report justify-content-center '} style={{ padding: "0 10px", height: "fit-content" }} >

            <div className='m-1 my-3 px-1' style={{ fontSize: "0.55vw" }}>Key</div>

            <div className='row ' style={{ fontSize: "0.55vw" }}>
              <div className=" col-4 py-2 ps-2 pe-1" style={{ backgroundColor: "#ECF2FA", margin: "0px 6px 3px 9px " }} ></div>
              <div className='col-1 ' style={{ textAlign: "center", padding: "0px 4px 0px 0px" }}>{Math.floor(maximum * 0.20)}</div>
            </div>
            <div className='row ' style={{ fontSize: "0.55vw" }}>
              <div className=" col-4 py-2 ps-2 pe-1" style={{ backgroundColor: "#CADFEC", margin: "0px 6px 3px 9px " }} ></div>
              <div className='col-1 ' style={{ textAlign: "center", padding: "0px 4px 0px 0px" }}>{Math.floor(maximum * 0.40)}</div>
            </div>
            <div className='row ' style={{ fontSize: "0.55vw" }}>
              <div className=" col-4 py-2 ps-2 pe-1" style={{ backgroundColor: "#83BBDC", margin: "0px 6px 3px 9px " }} ></div>
              <div className='col-1 ' style={{ textAlign: "center", padding: "0px 4px 0px 0px" }}>{Math.floor(maximum * 0.60)}</div>
            </div>
            <div className='row ' style={{ fontSize: "0.55vw" }}>
              <div className=" col-4 py-2 ps-2 pe-1" style={{ backgroundColor: "#3082BC", margin: "0px 6px 3px 9px " }} ></div>
              <div className='col-1 ' style={{ textAlign: "center", padding: "0px 4px 0px 0px" }}>{Math.floor(maximum * 0.80)}</div>
            </div>
            <div className='row ' style={{ fontSize: "0.55vw" }}>
              <div id='example' className=" col-4 py-2 ps-2 pe-1" style={{ backgroundColor: "#08519C", margin: "0px 6px 3px 9px " }} ></div>
              <div className='col-1 ' style={{ textAlign: "center", padding: "0px 4px 0px 0px" }}>{maximum}</div>
            </div>

          </table>
        </div>
        <div className="col-1 mt-1" id="report_download_buttons">
          {
            isVisible !== "1" ? <div className='  px-1 mb-2' onClick={() => printDocument()} role="button"><img src={pdf} style={{ height: "4.4vw" }} /></div> : null
          }
          {
            isVisible !== "1" ?
              <div className='pb-1 ' onClick={() => downloadCSVData()} role="button"><img src={csv} style={{ height: "5vw" }} /></div> : null
          }
        </div>
      </div>

    </div>
  )
}
export default Heatmap;