import axios from "axios";

export const ApiHandler = async ({ endPoint, method, reqParam }) => {
  const acceptType = "application/json";
  const contentType =
    reqParam instanceof FormData ? "multipart/form-data" : "application/json";
  let response = null;
  
  try {
    let content = {
      method: method.toUpperCase(),
      headers: apiHeaders(reqParam, contentType, acceptType),
    };

    if (
      method === "post" ||
      method === "delete" ||
      method === "patch" ||
      method === "put"
    ) {
      content = { ...content, body: JSON.stringify(reqParam) };
    }

    if (contentType === "multipart/form-data") {
      let header = apiHeaders(contentType, acceptType);
      response = await axios.post("/" + endPoint, reqParam, header);
    } else {
      response = await fetch("/" + endPoint, content);
    }
  } catch (e) {
    console.log(e);
  }


  if (response) {
    const resContentType = response.headers.get("Content-type");
    let data = null;

    if (resContentType === null) return Promise.resolve(null);
    else if (resContentType.includes("application/json")) data = await response.json();
    else if (resContentType.includes("text/html")) data = await response.json();
    else if (resContentType.includes("text/plain")) data = await response.text();
    response = {
      data: data,
      status: response?.status,
      statusText: response?.statusText,
    };
  }

  if (response?.data?.token) {
    localStorage.setItem("token", response.data.token);
  }

  if (response?.data?.isAdmin) {
    localStorage.setItem("ACCESS", response.data.isAdmin =="admin" ? 1:0);
  }

  if ( response.status == 401 || (response?.data?.status === "error" && response?.data?.errCode === "ExpiredTokenException") ) {
    localStorage.clear();
    return response;
  } 
  
  else {
    return response;
  }
};

export function apiHeaders(reqParam, contentType, acceptType) {
  const requestHeader =
    reqParam instanceof FormData
      ? {
          responseType: "blob",
          "x-api-key": "web",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : "",
        }
      : {
          Accept: acceptType,
          "Content-Type": contentType,
          Authorization: localStorage.getItem("token")
            ?  "Bearer " +localStorage.getItem("token")
            : "",
        };
  const headers = requestHeader;
  return headers;
}
