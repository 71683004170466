import { Heatmap } from '../../components/';
import { useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react'
import { ApiHandler } from '../../hooks/apiHandler';
import { ENDPOINTS, API_METHODS } from '../../util/constants/constants';
import moment from 'moment';
import { AuthContext } from '../../hooks/auth';

const getNZDate = () => {
    var date = new Date();
    var utcTime = date.getTime() + (date.getMonth() >= 3 && date.getMonth() <=8 ?  date.getTimezoneOffset() * 60000 : ( date.getTimezoneOffset() + 60) * 60000);
    var timeOffset = 12;
    var NewZealandTime = new Date(utcTime + (3600000 * timeOffset));
    return NewZealandTime;
}

const Print = () => {
    const [searchParams] = useSearchParams();
    var from = searchParams.get('from');
    var to = searchParams.get('to');
    var device_q = searchParams.get('device');
    var geofence_q = searchParams.get('geofence');
    var reportName_q = searchParams.get('name');

    const today = parseInt(moment(new Date()).format('YYYYMMDD'));
    const [isLoading, setIsLoading] = useState(false);
    const [fromF, setFromF] = useState(today);
    const [toF, setToF] = useState(today);
    const [fromDate, setFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment(getNZDate()).format('YYYY-MM-DD'));
    const [geofenceData, setGeofenceData] = useState([]);

    //geofence Data state
    const [geofenceSelectedData, setGeofenceSelectedData] = useState(JSON.parse(geofence_q));
    const [vehicleSelectedData, setVehicleSelectedData] = useState(JSON.parse(device_q));
    const [deviceData, setDeviceData] = useState([]);

    //accoridan states
    const [heatmapData, setHeatmapData] = useState(null);
    const [title, setTitle] = useState(reportName_q);
    const { isLoggedIn, logout } = useContext(AuthContext)

    const dateFormat = async (ts) => {
        var date = new Date(ts * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = date.getFullYear();
        var month = months[date.getMonth()];
        var dat = date.getDate();
        var tim = `${dat}-${month}-${year}`;
        return tim
    };

    var from_format =  dateFormat(from);
    from_format.then(function(result) {
        setFromF(result)
    });

    var to_format =  dateFormat(to);
    to_format.then(function(result) {
        setToF(result)
    });

    useEffect(() => {
        getGeofenceData();
        getDeviceData();
        getHeatmapData();
    }, [])


    const getGeofenceData = async () => {
        const reqParam = {};
        const method = API_METHODS.GET;
        const endPoint = ENDPOINTS.getGeofenceData;
        try {
            const response = await ApiHandler({ reqParam, method, endPoint });
            setGeofenceData(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getDeviceData = async () => {
        const reqParam = {};
        const method = API_METHODS.GET;
        const endPoint = ENDPOINTS.getAllDevice;
        try {
            const response = await ApiHandler({ reqParam, method, endPoint });
            setDeviceData(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getHeatmapData = async () => {
        setIsLoading(true)
        let reqParam = {};
        let d = new Date();
        if (fromDate && toDate) {
            reqParam = {
                from: (from),
                to: (to),
            }
        }
        if (geofenceSelectedData?.length) {
            reqParam.geofenceId = geofenceSelectedData
        }
        if (vehicleSelectedData?.length) {
            reqParam.deviceId = vehicleSelectedData
        }
        const method = API_METHODS.POST;
        const endPoint = ENDPOINTS.getHeatmapData;
        try {
            const response = await ApiHandler({ reqParam, method, endPoint });
            if (response.status == 401) {
                logout();
                return
            }
            setHeatmapData(response.data);
            setIsLoading(false);
            setTimeout(() => {
                window.domcontentloaded = true;
            }, 5000)

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>

            <div className=' row align-items-start justify-content-start pdf-print-1' id='pdfprint' >
                <div className='  col-12' id='pdfprint1'>
                    <div
                        style={{ border: "1px solid rgba(0,0,0,0.2)" }}
                        className='form-field-shadow-filter-big-box-1 col-10 row justify-content-start  align-items-center'>

                        <div style={{ fontSize: "1.2vw", textAlign: "center" }} className='col-12'>
                            <b>{title}</b>
                        </div >
                    </div>
                    {heatmapData == null ? null : <Heatmap isVisible="1" borderClass="heatmap_table" classNum="glass_container_2" data={heatmapData} title={title} />}
                </div>
                <div className='mx-3 col-10' id='pdfprint2'>
                    <div
                        style={{ border: "1px solid rgba(0,0,0,0.2)" }}
                        className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-center'>
                        <div style={{ fontSize: "1.1vw", textAlign: "left" }} className='col-3'>
                            Date
                        </div >
                        <div style={{ fontSize: "1.2vw", textAlign: "left" }} className='col-9'>
                            <b>{`${fromF} - ${toF}`}</b>
                        </div >
                    </div>
                    <div style={{ border: "1px solid rgba(0,0,0,0.2)" }} className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-start'>
                        <div style={{ fontSize: "1vw", textAlign: "left" }} className='col-3'>
                            Geofence
                        </div >
                        <div style={{ fontSize: "0.8vw", textAlign: "left" }} className=' row col-9'>

                            {(geofenceSelectedData.length == geofenceData.length || geofenceSelectedData.length == 0 ) ? <div>All</div> : geofenceData.map((val, key) => {
                                return geofenceSelectedData.includes(val.code) ? (
                                    <div className='col-2' key={key}>
                                        <b> {val.name}</b>
                                        <br />
                                    </div>
                                ) : (<></>)
                            })}

                        </div >
                    </div>
                    <div style={{ border: "1px solid rgba(0,0,0,0.2)" }} className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-start'>
                        <div style={{ fontSize: "1vw", textAlign: "left" }} className='col-3'>
                            Vehicles
                        </div >
                        <div style={{ fontSize: "0.8vw", textAlign: "left" }} className='row col-9'>

                            {(vehicleSelectedData.length == deviceData.length || vehicleSelectedData.length == 0) ? <div>All</div> : deviceData.map((val, key) => {
                                return vehicleSelectedData.includes(val.device_id) ? (
                                    <div className='col-2' key={key}>
                                        <b> {val.name}</b>
                                        <br />
                                    </div>
                                ) : (<></>)
                            })}
                        </div >
                    </div>
                </div>
            </div>
        </>
    )
}
export default Print;