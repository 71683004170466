import React, { useEffect, useState, useContext } from 'react'
import { TextInput, SelectInput } from '../Input'
import { ApiHandler } from '../../hooks/apiHandler';
import { ENDPOINTS, API_METHODS, SELECTINPUT } from '../../util/constants/constants';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChevronLeft, faChevronRight, faChevronUp, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { HeatmapAccordian } from '.';
import { Link } from 'react-router-dom';
import { ReportContext } from '../../hooks/reportContext';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext } from '../../hooks/auth';
import addRecipient from '../../assets/images/addRecipients.svg'



const NewReportForm = props => {
  const location = useLocation();
  const { scheduleReport, getReport } = useContext(ReportContext);
  const [geofence, setGeofence] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState(new Date().toISOString().slice(0, 10));
  const [geofenceData, setGeofenceData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [isDay, setIsDay] = useState(true);
  const [isWeek, setIsWeek] = useState(false);
  const [isMonth, setIsMonth] = useState(false);
  const [isYear, setIsYear] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [count, setCount] = useState(3);
  const [recipients, setRecipients] = useState([]);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [reportName, setReportName] = useState([]);
  //geofence Data state
  const [geofenceSelectedData, setGeofenceSelectedData] = useState([]);
  const [vehicleSelectedData, setVehicleSelectedData] = useState([]);
  //accoridan states
  const [geofenceAccordian, setGeofenceAccordian] = useState(true);
  const [vehicleAccordian, setVehicleAccordian] = useState(true);

  const [reportData, setReportData] = useState(null)
  const [weekDay, setWeekDay] = useState(0);
  const [monthDate, setMonthDate] = useState(1)
  const [vehicleSelectedName, setVehicleSelectedName] = useState([]);
  const [geofenceSelectedName, setGeofenceSelectedName] = useState([]);

  const {logout , isLoggedIn , setToken} = useContext(AuthContext);

  //getGeofenceData for select options
  useEffect(() => {
    getGeofenceData();
    getDeviceData();
  }, []);


  useEffect(() => {
    const { search } = location;
    if (search) {
      console.log(location)

      const reportId = search.substring(1, search.length);
      const payload = {
        reportId: reportId
      }

      getReport(payload).then((response) => {
        console.log(response.data)
        setReportData(response?.data)
        updateReportData(response?.data)
      })
        .catch(error => {
          console.log(error)
        })


    }
  }, [location?.search]);


  const updateReportData = (data) => {console.log(data)
    setReportName(data?.reportName);
    setVehicleSelectedData(data?.device);
    setGeofenceSelectedData(data?.geofence);
    setRecipients(data?.Recipients);
    if(data?.weekDay){
      setWeekDay(data?.weekDay)
    }
    if(data?.monthDate){
      setMonthDate(data?.monthDate)
    }
    const { frequency } = data;
    setIsDay(false)
    setIsWeek(false)
    setIsMonth(false)
    setIsYear(false)
    if (frequency == 1) {
      setIsDay(true)
    } else if (frequency == 2) {
      setIsWeek(true)
    } else if (frequency == 3) {
      setIsMonth(true)
    } else {
      setIsYear(true);
    }
    // setTime
    // let unix_timestamp = data?.schduledTimestamp
    // var date = new Date(unix_timestamp * 1000);
    // setWeekDay(date.getDay())
    // setMonthDate(date.getDate());
    // var hours = date.getHours();

    // var minutes = "0" + date.getMinutes();

    // var seconds = "0" + date.getSeconds();


    // var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    // console.log(formattedTime);
    // setScheduleTime(formattedTime)
    setScheduleTime( data?.schduledTimestamp)
  }

  const getGeofenceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getGeofenceData;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if(response?.statusCode == 401){
        logout();
      }
      setGeofenceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDeviceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllDevice;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setDeviceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };


  const getFrequency = () => {
    if (isDay)
      return 1;
    else if (isWeek)
      return 2;
    else if (isMonth)
      return 3;
    return 4;

  }


  const onSubmit = () => {
    if (reportName == "" || reportName[0] == " ") {
      toast.error("Please provide valid report name !")
    } else {
      // getHeatmapData(); 
      // let day = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
      // let currentDate = Date.parse(day + " " + scheduleTime) / 1000;
      let frequency = getFrequency();
      // var dt = new Date();
      // var month = dt.getMonth() + 1;
      // var year = dt.getFullYear();
      // var daysInMonth = new Date(year, month, 0).getDate();
      // var daysInYear = ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365;


      // if (frequency == 1) {
      //   //currentDate+=86400;
      // } else if (frequency == 2) {
      //   if (weekDay >= new Date().getDay()) {
      //     currentDate += Math.abs(new Date().getDay() - weekDay) * 86400;
      //     if (currentDate <= new Date().getTime() / 1000) {
      //       currentDate += 7 * 86400;
      //     }
      //   } else
      //     currentDate += Math.abs(7 - (new Date().getDay() - weekDay)) * 86400;
      // } else if (frequency == 3) {
      //   if (monthDate >= new Date().getDate()) {
      //     currentDate += Math.abs(new Date().getDate() - monthDate) * 86400;
      //     if (currentDate <= new Date().getTime() / 1000) {
      //       currentDate += daysInMonth * 86400;
      //     }
      //   } else {
      //     currentDate +=
      //       Math.abs(daysInMonth - (new Date().getDate() - monthDate)) * 86400;
      //   }
      // } else {
      //   currentDate += daysInYear * 86400;
      // }


      const payload = {
        name: reportName,
        geofence: geofenceSelectedData,
        device: vehicleSelectedData,
        frequency: frequency,
        Recipients: recipients,
        schduledTimestamp: scheduleTime,
        isEdit: reportData ? true : false,
        reportId: reportData?._id,
        weekDay:weekDay,
        monthDate:monthDate

      }

      scheduleReport(payload);
    }
  }

  const addRecipients = (i, value, key) => {
    var tempRecipients = recipients;

    tempRecipients[i] = { ...tempRecipients[i], [key]: value }

    setRecipients(tempRecipients);
    console.log(tempRecipients)
  }

  const recipientForm = (i) => {
    return (
      <>
        <div className=" report-form-wrapper justify-content-start col-6">
          <div className=' report-form-input'>
            <input className=' col-12 account-form-input-box' placeholder='Name' value={recipients[i]?.name} type='text' name='email' onChange={(e) => {
              addRecipients(i, e.target.value, "name");
            }}></input>
          </div>
        </div>
        <div className=" report-form-wrapper justify-content-start col-6">
          <div className=' report-form-input '>
            <input className=' col-12 account-form-input-box' placeholder='Email' value={recipients[i]?.email} type='text' name='email' onChange={(e) => {
              addRecipients(i, e.target.value, "email");
            }}></input>
          </div>
        </div>
      </>)
  }


  return (
    <div className='row'>
      <div style={{
        overflowY: "auto",
        height: "95vh",
      }} className='mx-3 col-5'>

        <div className="pt-4 col-12">
          <Link style={{ color: "black" }} to="/report">
            <FontAwesomeIcon icon={faChevronLeft} className="me-4" size="lg" />
          </Link>


          <div className=' report-form-input-1 p-2 '>
            <input className=' col-12 account-form-input-box' placeholder='New Report' value={reportName} type='text' name='new-report' onChange={(e) => {
              setReportName(e.target.value);
            }}></input>
          </div>
        </div>

        {/* filter-time-duration-box */}

        <div className="report-form-shadow-filter-box">
          <div className="report-form-heading">
            <div className=''> Frequency </div>
          </div>
          <div className="form-field-shadow-filter-box-time">
            <div className={isDay ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
              setIsDay(true)
              setIsWeek(false)
              setIsMonth(false)
              setIsYear(false)
            }} >Daily</div>

            <div className={isWeek ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
              setIsDay(false)
              setIsWeek(true)
              setIsMonth(false)
              setIsYear(false)
            }} >Weekly</div>
            <div className={isMonth ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
              setIsDay(false)
              setIsWeek(false)
              setIsMonth(true)
              setIsYear(false)
            }} >Monthly</div>
            <div className={isYear ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
              setIsDay(false)
              setIsWeek(false)
              setIsMonth(false)
              setIsYear(true)
            }} >Yearly</div>
          </div>
        </div>

        {/* accordian-date-selection-box */}
        {/* <div className="report-form-shadow-filter-box">
          <div className="report-form-heading">
            <div className='accordian-title'> Date Time Frequency </div>
          </div>
          <div className="form-field-date-input">
            From
            <TextInput
              type="time"
              name="fromTime"
              className="form-field__heatmap"
              value={fromTime}
              onChange={(e) => {
                setFromTime(e.target.value)
              }}
            />
          </div>
          <div className="form-field-date-input">
            To
            <TextInput
              type="time"
              name="toTime"
              className="form-field__heatmap"
              value={toTime}
              onChange={(e) => {
                setToTime(e.target.value)
              }}
            />
          </div>
        </div> */}

        {/* accordian-geofence-box */}

        <HeatmapAccordian
          title="Geofences"
          accordianState={geofenceAccordian}
          setAccordianState={setGeofenceAccordian}
          accordianData={geofenceData}
          accordianSelectedData={geofenceSelectedData}
          setAccordianSelectedData={setGeofenceSelectedData}
          isAccordian={false}
          setIdsParam={"code"}
          accordianSelectedName={geofenceSelectedName}
          setAccordianSelectedName={setGeofenceSelectedName}
          name={"name"}
        />


        {/* accordian-vehicles-box */}

        <HeatmapAccordian
          title="Vehicles"
          accordianState={vehicleAccordian}
          setAccordianState={setVehicleAccordian}
          accordianData={deviceData}
          accordianSelectedData={vehicleSelectedData}
          accordianSelectedName={vehicleSelectedName}
          setAccordianSelectedData={setVehicleSelectedData}
          setAccordianSelectedName={setVehicleSelectedName}
          isAccordian={false}
          setIdsParam={"device_id"}
          name={"name"}
        />


      </div>
      <div className='mt-5 mx-3 col-5'>
        <div className='mt-5'></div>
        {/* filter-time-duration-box */}
        {!isYear ?
          <div className="report-form-shadow-filter-box">
            <div className="report-form-heading">
              <div className=''> Send Report Time</div>
            </div>
            <div className='row align-items-center'>

              <div className='col-5' role="button">
                {/* <b>Time</b> */}
                <TextInput
                  type="time"
                  name="scheduleTime"
                  className="form-field__heatmap"
                  value={scheduleTime}
                  onChange={(e) => {
                    setScheduleTime(e.target.value)
                  }}
                />
              </div>
              {
                isWeek ?
                  <div className="col-5 ">
                    <b className='me-3'>Every</b>
                    <SelectInput
                      //onChange={(e)=>setWeekDay((parseInt(e.target.value)+1)%7)}
                      onChange={(e) => { setWeekDay(parseInt(e.target.value)) }}
                      className="report-form-input-2 p-2"
                      value={weekDay}
                      options={SELECTINPUT.week}
                    />
                  </div>
                  : null
              }
              {
                isMonth ?
                  <div className="col-7 ">
                    <b className='me-3'>On the</b>
                    <SelectInput
                      onChange={(e) => setMonthDate(parseInt(e.target.value))}
                      className="report-form-input-2 p-2"
                      value={monthDate}
                      options={SELECTINPUT.month}
                    />
                    <b className='ms-3'>Of every month</b>
                  </div>
                  : null
              }

              {/* <div className='col-1 schedule-send-button'>Sun</div> */}
            </div>
          </div>
          : null}
        {/* accordian-date-selection-box */}
        <div className=" row report-form-shadow-filter-box">
          <div className="report-form-heading">
            <div className='accordian-title'> Recipients</div>
          </div>

          <div className='mt-3 col-6 report-form-recipients'>
            Name
          </div>
          <div className='mt-3 col-6 report-form-recipients'>
            Email
          </div>


          {
            Array(count).fill(0).map((val, i) => {
              return (
                <>
                  {recipientForm(i)}
                </>
              )
            })
          }
          <div className=' w-50 report-form-shadow-filter-box' role={"button"} onClick={() => { setCount(count + 1) }}><img src={addRecipient} style={{ height: 25, width: 25 }} className="me-4 mb-1" />  Add Recipient</div>

        </div>
        <div style={{ marginTop: 100 }}>
          <div style={{ borderRadius: 5 }} role="button" className=' w-50 report-form-shadow-filter-box bg-primary text-white' onClick={onSubmit}> Save</div>
        </div>
      </div>
    </div>
  )
}

export default NewReportForm