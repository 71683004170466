import React,  {useState , useEffect} from "react";
import { API_METHODS ,ENDPOINTS } from "../util/constants/constants";
import { ApiHandler } from "./apiHandler";


export const AuthContext = React.createContext({
    token : "",
    isLoggedIn : false,
    isAdmin : false,
    login : (data) =>{},
    logout : () =>{},
    setToken : (token) => {}
})

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isAdmin , setIsAdmin] = useState(false);
    var isLoggedIn = !!token;

    useEffect(() => {
        var authToken = localStorage.getItem("token");
        var role = localStorage.getItem("ACCESS");
        if (authToken) {
          setToken(authToken);
        }
        if(role){
          setIsAdmin(parseInt(role))
        }
        console.log("is logged in : "+ isLoggedIn)
      }, []);

      useEffect(() =>{
          isLoggedIn = !!token;
          if(!isLoggedIn) logoutHandler();
          var role = localStorage.getItem("ACCESS")
          if(role)
           setIsAdmin(parseInt(role));
     
      },[token]);
    

    const loginHandler =  async (data) => {
        try {
          const response = await ApiHandler({
            reqParam: data,
            method: API_METHODS.POST,
            endPoint: ENDPOINTS.login,
          });
          if (response.status === 200) {
            setToken(response.data.token);
            setIsAdmin(response.data.isAdmin);
          }
          return response;
        } catch (err) {
          console.log(err);
        }
      };
    
    const logoutHandler = () =>{
        localStorage.clear();
        setToken(null);
    }

    const contextValue = {
        token : "",
        isLoggedIn : isLoggedIn,
        login : loginHandler,
        logout : logoutHandler,
        setToken : setToken,
        isAdmin : isAdmin
    }
    return <AuthContext.Provider value= {contextValue}>{props.children}</AuthContext.Provider>
}
