import React, {useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEdit, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReportContext } from "../../hooks/reportContext";
import {Link} from 'react-router-dom'
import edit from '../../assets/images/edit.png'
import { ApiHandler } from '../../hooks/apiHandler';
import { ENDPOINTS, API_METHODS } from '../../util/constants/constants';
import { ThreeBounce } from 'better-react-spinkit'
import { toast } from "react-toastify";
import moment from "moment";


const ReportDashboard = props => {

    const {toggleStatus,getAllReports,reports} =useContext(ReportContext)
    const [loading, setloading] = useState(true); 
    const frequency={
        1:'Daily',
        2:'Weekly',
        3:'Monthly',
        4:'Yearly'
    }

    const toggleReportStatus=async(report)=>{
        setloading(true); 
        toggleStatus(report)
        setloading(false);
    }

    const deleteReport = async (id) => {
        const reqParam = {};
        const method = API_METHODS.GET;
        const endPoint = ENDPOINTS.deleteReport(id);
        try {
          const response = await ApiHandler({ reqParam, method, endPoint });
          getReports(); 
          toast.success("Report Deleted successfully");
        } catch (err) {
          console.log(err);
        }
      };

    const getReports=async()=>{
        setloading(true); 
        const response=await getAllReports();
        setloading(false); 
    }

    useEffect(()=>{
        getReports(); 
    },[])

    const dateFormat = (date) => {
    //    return date.getDate()+
    //       "/"+(date.getMonth()+1)+
    //       "/"+date.getFullYear()+
    //       " "+date.getHours()+
    //       ":"+date.getMinutes()
        //   return date.toLocaleDateString("en-NZ", { 
        //     year: "numeric",
        //     month: "2-digit",
        //     day: "2-digit",
        //     hour: "2-digit",
        //     minute:"2-digit",
        //     hour12: false
        //   })
          
         return moment(date).format('DD/MM/YYYY HH:mm')
    }

    const Report=({report,index})=>{
       return (
        <Link  to={{
            pathname:"/edit-report",
            search:report?._id
        }} className="row justify-content-between users-dashboard-blue-box link-unstyled " style={{backgroundColor:index%2?'#fff':'#F6F9FE'}}>
         <div className="col-2 report-dashboard-content"> {report?.reportName}</div>
         <div className="col-1 report-dashboard-content"> {report?.geofence?.length} </div>
         <div className="col-1 report-dashboard-content"> {report?.Recipients?.length} </div>
         <div className="col-1 report-dashboard-content">  {frequency[report?.frequency]} </div>
         <div className="col-2 report-dashboard-content"> { dateFormat(new Date(report?.nextReportOn * 1000)) }</div>
         {/* <div className="col-2 report-dashboard-content"> { moment(report?.nextReportOn*1000).utcOffset('+1200').format('DD/MM/YYYY HH:mm')}</div> */}
         <div className="col-1 report-dashboard-content">  
            <Link  to={{
                pathname:"/edit-report",
                search:report?._id
            }} >
              {/* <FontAwesomeIcon  icon={faEdit} color="#000"  size="lg" /> */}
              <img src={edit} style={{height:15,width:15}}/>
            </Link>
         </div>
         {/* <Link  to={{pathname:"/report" }} > */}
         <div className="col-1 report-dashboard-content"><Link  to={{pathname:"/report" }} > <FontAwesomeIcon type="button" onClick={()=>{toggleReportStatus(report)}}  icon={report?.isActive?faToggleOn:faToggleOff} color="#0096FF"  size="2x" /></Link></div>
         <div className="col-1 report-dashboard-content"><Link  to={{pathname:"/report" }} > <FontAwesomeIcon type="button" onClick={()=>{deleteReport(report?._id)}}  icon={faTrash} color="#0096FF"  size="2x" /></Link></div>
         {/* </Link> */}
        </Link>
       )
    }

    return (
        <div className="  users-dashboard-outer-box">
            <div className="row justify-content-between report-dashboard-title-box">
                <div className="col-2 report-dashboard-title"> Report Name</div>
                <div className="col-1 report-dashboard-title"> Geofences </div>
                <div className="col-1 report-dashboard-title"> Recipients </div>
                <div className="col-1 report-dashboard-title"> Frequency </div>
                <div className="col-2 report-dashboard-title"> Next Report On</div>
                <div className="col-1 report-dashboard-title"> Edit</div>
                <div className="col-1 report-dashboard-title"> Status</div>
                <div className="col-1 report-dashboard-title"> Delete</div>
            </div>
            <div className=" users-dashboard-blue-outer-box">
            {
                loading ? 
                <div className="row justify-content-between users-dashboard-blue-box">
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-2 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                            <div className="col-1 ms-2 report-dashboard-content"> <ThreeBounce size={5} color='blue' /></div>
                        </div> : null
            }
             {reports?.length>0?
              reports?.map((report,index)=>{
                  return <Report key={report?._id} index={index} report={report}/>
              })
              :null}
             
            </div>
        </div>
    )

}

export default ReportDashboard