import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ReportDashboard from '../../components/Reports/reportDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const Report = props => {

  return (
    <div className='col-10'>
      <div className='row justify-content-between '>
        <div className=' col-4 report-page-title'>Reports</div>
        <Link className='col-2 report-create-new-button row justify-content-start align-items-center' to="/new-report">
          <FontAwesomeIcon icon={faPlus} className="col-1" />
          <div className='col-8 d-inline'>Create New</div>
        </Link>
      </div>


      <ReportDashboard />

    </div>
  )
}


export default Report;