import React from 'react'
import { Forms } from '../../components';

const Registeration = props => {
  return (
    <><Forms.RegisterationForm /></>
    );
}


export default Registeration