import React, { useState, useCallback, useEffect } from 'react'
import { ApiHandler } from '../../hooks/apiHandler';
import { API_METHODS } from '../../util/constants/constants';
import { ENDPOINTS } from '../../util/constants/constants';
import { validateEmail, matchPassword, validateName } from "../../util/constants/helper";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit ,faEdit} from '@fortawesome/free-solid-svg-icons';
import { GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
import { faSave } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import tnc from '../../assets/images/tnc.pdf';
import pdf from "../../assets/images/pd.png"
import edit from '../../assets/images/edit.png'

const Account = props => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cnfPassword, setCnfPassword] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isDetails, setIsDetails] = useState(true);
  const [isTnc, setIsTnc] = useState(false);

  const validate = () => {
    return validateEmail(email) && validateName(firstName) && validateName(lastName) && matchPassword(password, cnfPassword);
  }

  const getUserData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getUserData;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setFirstName(response.data.firstname);
      setLastName(response.data.lastname);
      setEmail(response.data.email);
    } catch (err) {
      console.log(err);
    }
  };

  const printDocument = () => {
    const input = document.getElementById('pdf_tnc_area');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("report_pdf.pdf");
      })
      ;
  }

  const onEdit = async () => {
    if (!isEditable) {
      if (validate()) {
        try {
          const reqParam = registerationData();;
          const method = API_METHODS.PUT;
          const endPoint = ENDPOINTS.updateUserData;
          const response = await ApiHandler({ reqParam, method, endPoint });
          if (response.status !== 200) {
            response.data?.message ? toast.error(response.data.message) : toast.error("You have entered an invalid username or password");
          }
          else {
            toast.success(response.data?.message);
            setIsEditable(!isEditable)
          }
        } catch (e) {
          console.log(e);
          toast.error(e);
        }
      } else {
        toast.error("Please enter valid details");
      }
    } else {
      setIsEditable(!isEditable)
    }
  };

  const registerationData = () => {
    return {
      firstname: firstName,
      email: email,
      password: password,
      confirmPassword: cnfPassword
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className='col-9'>
      <div className='row justify-content-between '>
        <div className=' col-4 report-page-title'>Account</div>
      </div>
      <div>
        <div className="">
          <div className={isDetails ? " col-1 account-nav-button-blue" : "account-nav-button-gray"} onClick={() => {
            setIsTnc(false)
            setIsDetails(true)
          }} >Details</div>

          <div className={isTnc ? "account-nav-button-blue" : "account-nav-button-gray"} onClick={() => {
            setIsTnc(true)
            setIsDetails(false)
          }} >Terms and Conditions</div>

        </div>
      </div>
      <div className='row justify-content-center'>
      {
        isDetails ?
          <div className='row col-11 account-form-shadow-filter-box'>
            <div onClick={onEdit} className='col-1 account-edit-button p-2'>
              {isEditable ?  <img className='me-2' src={edit} style={{height:15,width:15}}/> : <FontAwesomeIcon icon={faSave} className="me-2" size="lg" />}
              {isEditable ? "Edit" : "Save"}
            </div>
            <div class="d-flex account-form-wrapper justify-content-start">

              <div class='d-flex account-form-input col-3'><label className='account-form-label' for='name'>First Name</label>
                <input className=' col-6 account-form-input-box' value={firstName} type='text' readOnly={isEditable} name='name' onChange={(e) => {
                  setFirstName(e.target.value)
                }}></input>
              </div>

              {/* <div class=' d-flex account-form-input col-3'><label className='account-form-label' for='name'>Last Name</label>
            <input className=' col-6 account-form-input-box' value={lastName} type='text' readOnly={isEditable} name='name' onChange={(e) => {
              setLastName(e.target.value)
            }}></input>
          </div> */}

            </div>

            <div class="d-flex account-form-wrapper justify-content-start">
              <div class='d-flex account-form-input col-3'><label className='account-form-label' for='email'>Email</label>
                <input className=' col-8 account-form-input-box' readOnly={isEditable} value={email} type='text' name='email' onChange={(e) => {
                  setEmail(e.target.value)
                }}></input>
              </div>

              <div class='d-flex account-form-input col-3  justify-content-start align-items-center'><label className='account-form-label ' for='phone'>Password</label>
                <input placeholder='******' className='col-6 account-form-input-box mt-2' readOnly={isEditable} value={password} type='password' name='password' onChange={(e) => {
                  setPassword(e.target.value)
                }}></input>
              </div>

              {!isEditable?<div class='d-flex account-form-input col-4  justify-content-start align-items-center'><label className='account-form-label' for='phone'> Confirm Password</label>
                <input placeholder='******' className=' col-6 account-form-input-box mt-2' readOnly={isEditable} value={cnfPassword} type='password' name='confirm-password' onChange={(e) => {
                  setCnfPassword(e.target.value)
                }}></input>
              </div>:null}
            </div>
          </div> : null}
      {
        isTnc ?
          <div className='row ms-5 col-11 account-tnc-outer-shadow-filter-box'>
            <div className='col-10 account-tnc-shadow-filter-box'>
              <embed
                id="pdf_tnc_area"
                src={tnc + "#toolbar=0&navpanes=0&scrollbar=0"} type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                height="100%"
                width="100%" />
              {/* <p
      id="pdf_tnc_area"
         style={{
            textAlign: "left", font: "normal normal medium 20px/25px Helvetica Neue",
            letterSpacing: "0px",
            color: "#000000",
            overflowY : "auto",
            height: "60vh",
          }}>
            Agreed variations to Terms: <br />
            The parties agree that the Terms are varied as follows: <br /><br />
            1. The Solution (including Hardware, Software and Services) will be provided by Optitrack to BT Mining in accordance with the requirements (including timeframes, terms, conditions and standards) set out in the Requirements Document. <br />
            2. All intellectual property rights in the methodology detailed in the Requirements Document and copyright in the Requirements Document is the exclusive property of BT Mining.<br />
            3. Anything paid for by BT Mining pursuant to this contract (including, but not limited to, the Hardware) and all data collected, stored, collated by, and/or made available to, BT Mining through the Solution (Data or Processed Data), will be the exclusive property of BT Mining. For avoidance of doubt: (a) the Data/Processed Data shall be considered BT Mining’s Confidential Information; and (b) this paragraph doesn’t extend to Third Party Software.<br />
            4. BT Mining grants Optitrac a licence to use BT Mining’s property described at paragraphs 2 and 3 above for the sole purpose of this contract.<br />
           <br/>  OPTITRAC - TERMS AND CONDITIONS <br /><br />
            1 Acceptance <br />
            1.1 These terms and conditions (Terms) apply to the: <br />
            1.1.1 purchase or lease of devices and/or accessories listed in the Contract Details that may be connected to your vehicles or equipment (Device(s)); and <br />
            1.1.2 services to be provided from time to time as described in the Contract Details, including all mobile applications and other software, data, reports, text, images, and content made available through any of the foregoing (Services). from OptiTrac Limited to the customer identified in the Contract Details (Customer, you, your, etc).<br />
            1.2 OptiTrac agree to supply the Devices and/or Services subject to these Terms and the Contract Details. By signing this agreement (or if this agreement is not signed, by receiving any Devices and/or using the Services) you agree to these Terms. The terms of any purchase order you submit to OptiTrac do not apply and are expressly excluded. <br />
            2 Services <br />
            2.1 OptiTrac will provide the Services to you: 2.1.1 using all reasonable care and skill;<br />
            2.1.2 in a competent and professional manner; <br />
            2.1.3 in accordance with the applicable service descriptions relating to the Services as set out in the Contract Details, although OptiTrac do not guarantee that the Services will be continuous or fault free; and <br />
            2.1.4 using people who have the necessary qualifications and experience.
          </p> */}
            </div>
            <a className='col-2 account-button__report   ' href={tnc} download><img src={pdf} style={{height : "4.4vw"}} /></a>
          </div>
          : null
      }
      </div>
    </div>
  )
}


export default Account;