import React, { useState } from "react";
import Heatmap from "../Heatmap";


const HeatmapPrint = ({
    geofenceSelectedName,
    geofenceSelectedData,
    geofenceData,
    vehicleSelectedData,
    vehicleSelectedName,
    deviceData,
    title,
    heatmapData,
    downloadCSVData,
    fromDate,
    toDate 
}) => {


    return (
        <div className=' row align-items-start justify-content-start pdf-print' id='pdfprint' style={{ display: 'none' }}>

            <div className='  col-12' id='pdfprint1'>
                <div
                    style={{ border: "1px solid rgba(0,0,0,0.2)" }}
                    className='form-field-shadow-filter-big-box-1 col-10 row justify-content-start  align-items-center'>

                    <div style={{ fontSize: "1.2vw", textAlign: "center" }} className='col-12'>
                        <b>{title}</b>
                    </div >
                </div>
                {heatmapData == null ? null : <Heatmap isVisible="1" fromDate= {fromDate} toDate= {toDate} widthClass = " ps-5 mx-5 ms-5 heatmap_width " borderClass="heatmap_table" classNum="glass_container_1" data={heatmapData} downloadCSVData={downloadCSVData} title={title} />}
            </div>
            <div className='mx-3 col-10' id='pdfprint2'>
                <div
                    style={{ border: "1px solid rgba(0,0,0,0.2)" }}
                    className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-center'>
                    <div style={{ fontSize: "1.1vw", textAlign: "left" }} className='col-3'>
                        Date
                    </div >
                    <div style={{ fontSize: "1.2vw", textAlign: "left" }} className='col-9'>
                        <b>{title}</b>
                    </div >
                </div>
                <div style={{ border: "1px solid rgba(0,0,0,0.2)" }} className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-start'>
                    <div style={{ fontSize: "1vw", textAlign: "left" }} className='col-3'>
                        Geofence
                    </div >
                    <div style={{ fontSize: "0.8vw", textAlign: "left" }} className=' row col-9'>

                        {(geofenceSelectedName.length == 0) || (geofenceSelectedData.length == geofenceData.length) ? <div>All</div> : geofenceSelectedName.map((val, key) => {
                            return (
                                <div className='col-2' key={key}>
                                    <b> {val}</b>
                                    <br />
                                </div>
                            )
                        })}

                    </div >
                </div>
                <div style={{ border: "1px solid rgba(0,0,0,0.2)" }} className='form-field-shadow-filter-big-box-2 col-12 row justify-content-start  align-items-start'>
                    <div style={{ fontSize: "1vw", textAlign: "left" }} className='col-3'>
                        Vehicles
                    </div >
                    <div style={{ fontSize: "0.8vw", textAlign: "left" }} className='row col-9'>

                        {(vehicleSelectedName.length == 0) || (vehicleSelectedData.length == deviceData.length) ? <div>All</div> : vehicleSelectedName.map((val, key) => {
                            return (
                                <div className='col-2' key={key}>
                                    <b> {val}</b>
                                    <br />
                                </div>
                            )
                        })}
                    </div >
                </div>
            </div>
        </div>
    )

}

export default HeatmapPrint