import React, { useContext, useEffect, useState } from 'react'
import { TextInput, SelectInput } from '../Input'
import { ApiHandler } from '../../hooks/apiHandler';
import { ENDPOINTS, API_METHODS } from '../../util/constants/constants';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from "react-toastify";
import { faArrowRight, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { HeatmapAccordian } from '.';
import Heatmap from '../Heatmap';
import WanderingCubes from 'better-react-spinkit/dist/WanderingCubes';
import moment from 'moment';
import { AuthContext } from '../../hooks/auth';
import { ThreeBounce } from 'better-react-spinkit'
import HeatmapPrint from './HeatmapPrint';
import axios from 'axios';
const FileDownload = require('js-file-download');


function getYesterdayDate() {
  return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
}

const getTimeOffSet = (d, val) => {
  if(d.getMonth() >= 3 && d.getMonth() <=8) 
  return   d.getTimezoneOffset() * val 
  return ( d.getTimezoneOffset() + 60)* val
};

const getNZDate = () => {
  var date = getYesterdayDate();
  var utcTime = date.getTime() + (getTimeOffSet(date, 60000));
  var timeOffset = 12;
  var NewZealandTime = new Date(utcTime + (3600000 * timeOffset));
  return NewZealandTime;
}

const HeatmapForm = props => {
  const today = parseInt(moment(new Date()).format('YYYYMMDD'));
  const [geofence, setGeofence] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [fromDate, setFromDate] = useState(moment(getYesterdayDate()).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(getNZDate()).format('YYYY-MM-DD'));
  const [geofenceData, setGeofenceData] = useState([]);
  const [isDay, setIsDay] = useState(true);
  const [isWeek, setIsWeek] = useState(false);
  const [isMonth, setIsMonth] = useState(false);
  const [isYear, setIsYear] = useState(false);


  //geofence Data state
  const [geofenceSelectedData, setGeofenceSelectedData] = useState([]);
  const [geofenceSelectedName, setGeofenceSelectedName] = useState([]);
  const [vehicleSelectedData, setVehicleSelectedData] = useState([]);
  const [vehicleSelectedName, setVehicleSelectedName] = useState([]);
  const [deviceData, setDeviceData] = useState([]);

  //accoridan states
  const [geofenceAccordian, setGeofenceAccordian] = useState(false);
  const [vehicleAccordian, setVehicleAccordian] = useState(false);
  const [dateAccordian, setDateAccordian] = useState(false);
  const [timeSelectionAccordian, setTimeSelectionAccordian] = useState(false);
  const [filterAccordian, setFilterAccordian] = useState(false);
  const [heatmapData, setHeatmapData] = useState(null);
  const [title, setTitle] = useState('');
  const { isLoggedIn, logout } = useContext(AuthContext)
  //getGeofenceData for select options
  useEffect(() => {
    getGeofenceData();
    getHeatmapData();
    getDeviceData();
  }, []);



  function dateFormat(d) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var t = new Date(d);
    return t.getDate() + '-' + monthNames[t.getMonth()] + '-' + t.getFullYear();
  }

  const formatDate = (date) => {
    var d = new Date(parseInt(date) * 1000)
    d = d.toLocaleDateString("en-NZ", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replaceAll("/", "-")
    const [day, month, year] = d.split('-');
    const result = [year, month, day].join('-');
    return result;
  }

  useEffect(() => {
    if(isCustomDate == "2")
    {getHeatmapData();}
    if (fromDate !== toDate ) {
      if( isCustomDate !== "1")
      setTitle(dateFormat(fromDate) + " - " + dateFormat(toDate));
    } else{
    if( isCustomDate !== "1")
      setTitle(dateFormat(fromDate));
    }
  }, [fromDate, toDate])

  useEffect(() => {
    var myDate = new Date(toDate);
    var increment = 0;
    isDay ? increment = 0 : isWeek ? increment = 6 : isMonth ? increment = 29 : isYear ? increment = 364 : increment = 0;
    myDate.setDate(myDate.getDate() - increment);
    var y = myDate.getFullYear(),
      m = myDate.getMonth() + 1, // january is month 0 in javascript
      d = myDate.getDate();
    var pad = function (val) { var str = val.toString(); return (str.length < 2) ? "0" + str : str };
    var dateString = [y, pad(m), pad(d)].join("-");
    setFromDate(dateString)
  }, [isDay, isMonth, isWeek, isYear]);


  const increaseDate = (isIncrease) => {
    var fDate = new Date(fromDate);
    var tDate = new Date(toDate);
    var increment = 0;
    isDay ? increment = 1 : isWeek ? increment = 6 : isMonth ? increment = 29 : isYear ? increment = 364 : increment = 1;
    tDate.setDate(isIncrease ? tDate.getDate() + increment : tDate.getDate() - increment);
    if (parseInt(moment(tDate).format('YYYYMMDD')) > today) {
      console.log(tDate.getTime(), getNZDate().getTime())
      tDate = new Date()
      toast.error("Date can not be a future date");
    } else {
      fDate.setDate(isIncrease ? fDate.getDate() + increment : fDate.getDate() - increment);
      if (fDate > new Date()) {
        fDate = new Date()
      }
    }
    var y = fDate.getFullYear(),
      m = fDate.getMonth() + 1, // january is month 0 in javascript
      d = fDate.getDate();
    var pad = function (val) { var str = val.toString(); return (str.length < 2) ? "0" + str : str };
    var dateString = [y, pad(m), pad(d)].join("-");
    setFromDate(dateString)
    y = tDate.getFullYear();
    m = tDate.getMonth() + 1; // january is month 0 in javascript
    d = tDate.getDate();
    dateString = [y, pad(m), pad(d)].join("-");
    setToDate(dateString)

  }

  const getGeofenceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getGeofenceData;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setGeofenceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDeviceData = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.getAllDevice;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      setDeviceData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getHeatmapData = async () => {
    setIsLoading(true)
    let reqParam = {};
    let d = new Date();
    if (fromDate && toDate) {
      reqParam = {
        from: (new Date(fromDate).getTime() / 1000) + (getTimeOffSet(d, 60)),
        to: (new Date(toDate).getTime() / 1000) + (getTimeOffSet(d, 60)),
      }
    }
    console.log(JSON.stringify(reqParam));
    if (geofenceSelectedData?.length) {

      reqParam.geofenceId = geofenceSelectedData
    }
    if (vehicleSelectedData?.length) {
      reqParam.deviceId = vehicleSelectedData
    }
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.getHeatmapData;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      if (response.status == 401) {
        logout();
        return
      }
      props.setData(response.data);
      setHeatmapData(response.data);
      console.log(response.data)
      setIsLoading(false);

    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  }

  const downloadCSVData = async () => {
    setIsLoading(true);
    let d = new Date();
    const from = (new Date(fromDate).getTime() / 1000) + (getTimeOffSet(d, 60));
    const to = (new Date(toDate).getTime() / 1000) + (getTimeOffSet(d, 60));
    const token = localStorage.getItem("token");
    let data = { geofenceId: geofenceSelectedData.map(String), deviceId: vehicleSelectedData };
    console.log(data)
    axios({
      url: `/api/device/get-report?from=${from}&to=${to}&token=${token}`,
      method: 'post',
      data: data,
      responseType: 'blob',
    })
      .then(function (response) {
        if (from == to) {
          FileDownload(response.data, `RotowaroEmbankmentReport_${formatDate(to)}.csv`);
          setIsLoading(false);
        }
        else {
          FileDownload(response.data, `RotowaroEmbankmentReport_${formatDate(from)}_${formatDate(to)}.csv`);
          setIsLoading(false);
        }

      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  const onSubmit = () => {
    getHeatmapData();
    if (fromDate !== toDate ) {
      setTitle(dateFormat(fromDate) + " - " + dateFormat(toDate));
    } else{
      setTitle(dateFormat(fromDate));
    }
  }

  // const handleFromDateChange = (val) => {
  //   var myDate = new Date(val);
  //   var increment = 0;
  //   isDay ? increment = 0 : isWeek ? increment = 7 : isMonth ? increment = 30 : isYear ? increment = 365 : increment = 0;
  //   myDate.setDate(myDate.getDate() + increment);
  //   if (myDate > new Date()) {
  //     myDate = new Date()
  //   }
  //   var y = myDate.getFullYear(),
  //     m = myDate.getMonth() + 1, // january is month 0 in javascript
  //     d = myDate.getDate();
  //   var pad = function (val) { var str = val.toString(); return (str.length < 2) ? "0" + str : str };
  //   var dateString = [y, pad(m), pad(d)].join("-");
  //   setFromDate(val)
  //   setToDate(dateString)
  // }

  return (
    <>
      <div className={`${filterAccordian ? "col-4" : "col-2"} pe-2`}>

        <div className={`form-field-shadow-filter-big-box ${+ filterAccordian ? "col-12" : "col-5"}`}>

          <div style={{ position: "relative" }} className='row align-items-center'>
            <div style={{ fontSize: "0.9vw", fontWeight: "bold" }} className={`${!filterAccordian ? "col-6" : " "}`}>Filter</div>
            <FontAwesomeIcon role="button" onClick={() => {
              setFilterAccordian(!filterAccordian)
            }} icon={filterAccordian ? faChevronLeft : faChevronRight} className={`accordian-arrow ${!filterAccordian ? "col-3" : "col-1"}`} size="lg" />
          </div>
          {filterAccordian ? <div>

            {/* filter-time-duration-box */}
            <div className="form-field-shadow-filter-box">
              <div className="accordian-heading mb-1">
                <div className='accordian-title'> Time Selection  </div>
                <FontAwesomeIcon role="button" onClick={() => {
                  setTimeSelectionAccordian(!timeSelectionAccordian)
                  setDateAccordian(false)
                  setIsCustomDate("2")
                  setIsDay(true)
                  setIsWeek(false)
                  setIsMonth(false)
                  setIsYear(false)
                }} icon={timeSelectionAccordian ? faChevronUp : faChevronDown} className="col-1 accordian-arrow" size="lg" />
              </div>
              {timeSelectionAccordian ?
                <div>
                  <div className="pt-2 form-field-shadow-filter-box-time">
                    <div className={isDay ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
                      setIsDay(true)
                      setIsWeek(false)
                      setIsMonth(false)
                      setIsYear(false)
                    }} >Day</div>

                    <div className={isWeek ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
                      setIsDay(false)
                      setIsWeek(true)
                      setIsMonth(false)
                      setIsYear(false)
                    }} >Week</div>
                    <div className={isMonth ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
                      setIsDay(false)
                      setIsWeek(false)
                      setIsMonth(true)
                      setIsYear(false)
                    }} >Month</div>
                    <div className={isYear ? "form-field-time-filter-button-blue" : "form-field-time-filter-button-gray"} onClick={() => {
                      setIsDay(false)
                      setIsWeek(false)
                      setIsMonth(false)
                      setIsYear(true)
                    }} >Year</div>
                  </div>
                  {isLoading ? <div className='col-12 p-3 justify-content-center align-items-center' style={{ borderRadius: "10px", backgroundColor: "#F6F9FE" }} >
                    <ThreeBounce size={10} color='blue' />
                  </div> :
                    <div className='p-3 form-field-shadow-filter-big-box-1 col-12 row justify-content-between align-items-center'>
                      <FontAwesomeIcon role="button" onClick={() => {
                        increaseDate(false);
                        setIsCustomDate ("2")
                      }} icon={faChevronLeft} className="col-1 vertical-arrow" size="lg" />
                      <div style={{ fontSize: "0.9vw" }} className='col-6'>
                        {title}
                      </div >
                      <FontAwesomeIcon role="button" onClick={() => {
                        increaseDate(true);
                        setIsCustomDate ("2")
                      }} icon={faChevronRight} className="col-1 vertical-arrow" size="lg" />
                    </div>
                  }
                </div> : null}
            </div>



            {/* accordian-date-selection-box */}
            <div className="form-field-shadow-filter-box">
              <div className="accordian-heading mb-1">
                <div className='accordian-title'> Custom Date </div>
                <FontAwesomeIcon role="button" onClick={() => {
                  setDateAccordian(!dateAccordian)
                  setTimeSelectionAccordian(false)
                  !dateAccordian ? setIsCustomDate("1") : setIsCustomDate("2")
                }} icon={dateAccordian ? faChevronUp : faChevronDown} className="col-1 accordian-arrow" size="lg" />
              </div>
              {dateAccordian ?
                <div>
                  <div className="form-field-date-input">
                    From
                    <TextInput
                      type="date"
                      name="fromDate"
                      className="form-field__heatmap"
                      value={fromDate}
                      maxDate={new Date().toLocaleDateString('en-nz')}
                      onChange={(e) => {
                        setFromDate(e.target.value)
                        setIsCustomDate ("1")
                      }}
                    />
                  </div>
                  <div className="form-field-date-input">
                    To
                    <TextInput
                      type="date"
                      name="toDate"
                      className="form-field__heatmap"
                      minDate={new Date(fromDate).toLocaleDateString('en-nz')}
                      maxDate={new Date().toLocaleDateString('en-nz')}
                      value={toDate}
                      onChange={(e) => {
                        setIsCustomDate ("1")
                        setToDate(e.target.value)
                      }}
                    />
                  </div>
                  <Button className="form-field-date-submit-button" onClick={() => {
                    onSubmit();
                    setIsCustomDate ("2")
                  }}>
                    <FontAwesomeIcon role="button" icon={faArrowRight} className="me-1" size="lg" />
                  </Button>
                </div> : null}
            </div>

            {/* accordian-geofence-box */}
            <HeatmapAccordian
              title="Geofences"
              accordianState={geofenceAccordian}
              setAccordianState={setGeofenceAccordian}
              accordianData={geofenceData}
              accordianSelectedData={geofenceSelectedData}
              accordianSelectedName={geofenceSelectedName}
              setAccordianSelectedName={setGeofenceSelectedName}
              setAccordianSelectedData={setGeofenceSelectedData}
              isAccordian={true}
              setIdsParam={"code"}
              name={"name"}
            />
            {/* accordian-vehicles-box */}
            <HeatmapAccordian
              title="Vehicles"
              accordianState={vehicleAccordian}
              setAccordianState={setVehicleAccordian}
              accordianData={deviceData}
              accordianSelectedData={vehicleSelectedData}
              accordianSelectedName={vehicleSelectedName}
              setAccordianSelectedData={setVehicleSelectedData}
              setAccordianSelectedName={setVehicleSelectedName}
              isAccordian={true}
              setIdsParam={"device_id"}
              name={"name"}
            />
          </div> : null}
          {filterAccordian && <Button className="btn w-50 p-2" onClick={() => { getHeatmapData() }}> Search</Button>}
        </div>
      </div>
      {isLoading ?
        <div id="pdf_area" className={`row ${filterAccordian ? " col-5 " : " col-7"} p-5 justify-content-center align-items-center`} style={{ borderRadius: "20px", marginTop: "20px", marginBottom: "20px", backgroundColor: "#F6F9FE" }} >
          <ThreeBounce size={20} color='blue' />
        </div> :
        <div id="pdf_area">
          <div className='form-field-shadow-filter-big-box-1 col-10 row justify-content-between  align-items-center'>
            <FontAwesomeIcon role={"button"} onClick={() => {
              increaseDate(false);
              setIsCustomDate ("2")
            }} icon={faChevronLeft} className="col-1 vertical-arrow" size="lg" />
            <div style={{ fontSize: "0.9vw" }} className='col-6'>
              <b>{title}</b>
            </div >
            <FontAwesomeIcon role={"button"} onClick={() => {
              increaseDate(true);
              setIsCustomDate ("2")
            }} icon={faChevronRight} className="col-1 vertical-arrow" size="lg" />
          </div>

          {heatmapData == null ? null : <Heatmap classNum="glass_container" data={heatmapData} fromDate={fromDate} toDate={toDate} downloadCSVData={downloadCSVData} title={title} />}
        </div>
      }

      <HeatmapPrint
        fromDate={fromDate}
        toDate={toDate}
        geofenceSelectedName={geofenceSelectedName}
        geofenceSelectedData={geofenceSelectedData}
        geofenceData={geofenceData}
        vehicleSelectedData={vehicleSelectedData}
        vehicleSelectedName={vehicleSelectedName}
        deviceData={deviceData}
        title={title}
        heatmapData={heatmapData}
        downloadCSVData={downloadCSVData}
      />

      <div id="squareloader" className='square-loader row justify-content-between align-items-center' style={{ display: "none", backgroundColor: "rgba(0,0,0,0.2)", height: "100vh", width: "100vw" }}>
        <WanderingCubes className="loader-animate" size={100} cubeSize={12} color={"blue"} />
      </div>
    </>
  )
}

export default HeatmapForm

