//pages
import { Home, Login, Registeration, Account, Report, NewReport, Users,EditReport, Help, Print } from "../pages";

//components

// user routes
const authRoutes = [
  { path: "/login" ,  exact : true , component: <Login />}
];

const printRoutes = [
  { path: "/print" ,  exact : true , component: <Print />}
];

const userRoutes = [
  { path: "/", exact:true , component: <Home/> },
  { path: "/report", exact:true , component: <Report/> },
  { path: "/new-report", exact:true , component: <NewReport/> },
  { path: "/account", exact:true , component: <Account/> },
  { path: "/edit-report", exact:true , component: <EditReport/> }, 
  { path: "/help", exact:true , component: <Help/> }
 
];

const adminRoutes =  [
  { path: "/users", exact:true , component: <Users/> },
  { path: "/register", exact : true , component: <Registeration/> },

];

export { userRoutes , authRoutes,  adminRoutes, printRoutes};
