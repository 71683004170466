import React, { useEffect, useContext, useRef, useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { AuthContext } from '../../hooks/auth';
import Logo from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/fontawesome-free-solid';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { GoGraph } from "react-icons/go";
import { GrDocumentDownload } from "react-icons/gr";
import { FiUsers, FiUser, FiArrowLeft, FiInfo } from "react-icons/fi";
import back from '../../assets/images/back.png'
import heatmap from '../../assets/images/heatmap.png'
import pdf from '../../assets/images/pdf.png'
import { Link } from 'react-router-dom';

const Header = props => {

  const [activeState, setActiveState] = useState(0);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const isAdmin = parseInt(localStorage.getItem("ACCESS"));
  const ref = useRef();

  const styles = {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingLeft: 10,
    paddingRight: 10
  }


  return (
    <div ref={ref}>

      <Navbar collapseOnSelect expand="sm"  >
        <Container className='d-flex flex-column'>
          <Navbar.Brand href="/"><img className='sidebar__logo' src={Logo} /></Navbar.Brand>
          <Nav className='d-flex flex-column justify-content-between sidebar-container'>
            <div>
              <Link className={`row mt-4 side-button ${activeState == 0 ? "sidebar-button-active" : ""}`} onClick={() => { setActiveState(0) }} to={{ pathname: "/" }}>

                <div className='col-2 ps-2 pe-0'><img src={heatmap} style={{ height: 20, width: 20, maxWidth: 20 }} className="" /></div>
                <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Heatmap</span>

              </Link>
              <Link className={`row side-button ${activeState == 1 ? "sidebar-button-active" : ""}`} onClick={() => { setActiveState(1) }} to={{ pathname: "/report" }}>

                <div className='col-2 ps-2 pe-0'><img src={pdf} style={{ height: 20, width: 20, maxWidth: 20 }} className="" /></div>
                <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Reports</span>

              </Link>
              {isAdmin ?
                <Link className={`row side-button ${activeState == 2 ? "sidebar-button-active" : ""}`} onClick={() => { setActiveState(2) }} to={{ pathname: "/users" }}>

                  <div className='col-2 ps-2 pe-0'><FiUsers style={{ color: "black" }} className="" /></div>
                  <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Users</span>

                </Link>
                : <></>}
            </div>
            <div>

              <Link className={`row side-button ${activeState == 3 ? "sidebar-button-active" : ""}`} onClick={() => { setActiveState(3) }} to={{ pathname: "/help" }}>

                <div className='col-2 ps-2 pe-0'><FiInfo style={{ color: "black" }} className="" /></div>
                <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Help</span>

              </Link>
              <Link className={`row side-button ${activeState == 4 ? "sidebar-button-active" : ""}`} onClick={() => { setActiveState(4) }} to={{ pathname: "/account" }}>

                <div className='col-2 ps-2 pe-0'><FiUser style={{ color: "black" }} className="" /></div>
                <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Account</span>

              </Link>
              <Link className={`row mb-4 side-button ${activeState == 5 ? "sidebar-button-active" : ""}`} onClick={() => { logout(); setActiveState(5) }} to={{ pathname: "/login" }}>

                <div className='col-2 ps-2 pe-0'><img src={back} style={{ height: 10, width: 20 }} className=" " /></div>
                <span style={{ color: "black" }} className='col-6 ps-4 pe-0'>Logout</span>

              </Link>
            </div>
          </Nav>
        </Container>
      </Navbar>

    </div>
  )
}

export default Header